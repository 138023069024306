import React from 'react';

import Section from '../../Section';
import SectionTitle from '../../SectionTitle';
import NetworkList from '../../NetworkList';

const HomeInterests = () => (
    <Section
        title={
            <SectionTitle color="white" number="05">
                My Network
            </SectionTitle>
        }
        extra={<NetworkList />}
        background="primary"
        color="white"
        isBaseless
        hasDescription
    >
        <p>
            Although the vast majority of people get back to normal with Physiotherapy, there are a
            small number of cases where it&#39;s not the right place to be, or another
            professional&#39;s input would be of more help. I have a large network of other
            therapist fitness professionals, doctors and specialists I work closely with within both
            the NHS and private practice in Essex and London. If I am not the right people to help,
            I know the people that can help. These professionals might include;
        </p>
        <ul>
            <li>Your GP</li>
            <li>Consultants, Surgeons and Specialists</li>
            <li>Referring for further investigations and scans</li>
            <li>Sports massage therapists or another Physiotherapist/Chiropractor/Osteopath</li>
            <li>Podiatrist (including podiatric surgeons)</li>
            <li>Personal trainers and Strength & Conditioning coaches</li>
        </ul>
        <p>
            I work closely with these professionals on a weekly basis, referring and receiving
            clients to and from each other where needed. Your health and wellbeing is more important
            to me than your money, and I will not try to hold onto you if someone else can help you
            more effectively.
        </p>
    </Section>
);

export default HomeInterests;
