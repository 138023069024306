import React from 'react';
import PropTypes from 'prop-types';

import styles from './NetworkListItem.module.scss';

const NetworkListItem = ({ title, subtitle, description, image }) => (
    <article>
        <i className={styles.icon}>
            <img src={image} alt={`Logo for ${description || title}`} />
        </i>
        <small className={styles.subtitle}>{subtitle}</small>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{description}</p>
    </article>
);

NetworkListItem.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
};

export default NetworkListItem;
