import React from 'react';

import InterestListItem from './InterestListItem';

import gymImage from '../../images/interests/gym.jpg';
import hipGroinImage from '../../images/interests/hip-groin.jpg';
import hypermobilityImage from '../../images/interests/hypermobility.jpg';
import lowBackImage from '../../images/interests/low-back.jpg';

import styles from './InterestList.module.scss';

const data = [
    {
        id: 'gym',
        title: 'Gym related injuries',
        description: 'whether relating to pain, lifting technique or performance',
        image: gymImage,
        alt: 'Hand lifting a weight',
    },
    {
        id: 'hip-groin',
        title: 'Hip and groin pain',
        image: hipGroinImage,
        alt: 'Person stretching hamstring',
        isReverse: true,
    },
    {
        id: 'hypermobility',
        title: 'Hypermobility',
        description: "including Ehlers Danlos syndrome and Marfan's Syndrome)",
        image: hypermobilityImage,
        alt: 'Person jumping onto box',
        isReverse: true,
    },
    {
        id: 'low-back',
        title: 'Low back pain',
        image: lowBackImage,
        alt: 'Person stretching back',
    },
];

const InterestList = () => (
    <ul className={styles.root}>
        {data.map((item) => (
            <InterestListItem key={item.id} {...item} />
        ))}
    </ul>
);

export default InterestList;
