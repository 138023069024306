import React from 'react';

import Layout from '../components/Layout';
import HomeHero from '../components/HomeHero';
import Content from '../components/Content';

import HomeAbout from '../components/Home/HomeAbout';
import HomeApproach from '../components/Home/HomeApproach';
import HomeInterests from '../components/Home/HomeInterests';
import HomePromise from '../components/Home/HomePromise';
import HomeNetwork from '../components/Home/HomeNetwork';

const IndexPage = () => (
    <Layout type="home">
        <HomeHero />
        <Content>
            <HomeAbout />
            <HomeApproach />
            <HomeInterests />
            <HomePromise />
            <HomeNetwork />
        </Content>
    </Layout>
);

export default IndexPage;
