import React from 'react';

import Section from '../../Section';
import SectionTitle from '../../SectionTitle';

import bg from '../../../images/home/promise.jpg';

const HomeInterests = () => (
    <Section
        title={
            <SectionTitle color="white" number="04">
                My Promise
            </SectionTitle>
        }
        background={['xdark', bg]}
        color="white"
        isReverse
        hasDescription
    >
        <p>
            Rehab to Robust is not just another Physiotherapy service. There are hundreds of
            Physiotherapists, Osteopaths and Chiropractors you could see, so why choose Rehab to
            Robust?
        </p>
        <p>
            The goal of Rehab to Robust is simple - to show you how to get better as quickly as
            possible, in as few visits as possible.
        </p>
        <p>
            On average, I work with clients for no more than 3-4 sessions: You will not be sold
            unnecessary sessions, or receive treatments that simply fill time, rather than help you
            get better. You will however;
        </p>

        <ul>
            <li>Be listened to and receive a thorough and complete assessment of your problem</li>
            <li>
                Understand what is going on and how both Physiotherapy, and yourself, can help you
            </li>
            <li>Receive an expectation of how long your problem will take to get better</li>
            <li>
                Receive a full write-up of every session to ensure you know what&#39;s going on, and
                what the plan is
            </li>
            <li>Be seen as few times as possible, to save your time and money</li>
        </ul>
    </Section>
);

export default HomeInterests;
