import React from 'react';

import Section from '../../Section';
import SectionTitle from '../../SectionTitle';
import InterestList from '../../InterestList';
import Button from '../../Button';

const HomeInterests = () => (
    <Section
        title={<SectionTitle number="03">My Interests</SectionTitle>}
        extra={<InterestList />}
        actions={<Button to="/services">View All Services</Button>}
        background="xlight"
        isFullWidth
    >
        <p>
            My work in the NHS, private, and occupational health sectors have given me a broad range
            of experience with a whole host of clients and patients. Whilst I am comfortable
            assessing and treating anything that is appropriate for Physiotherapy, my particular
            areas of interest and speciality are;
        </p>
    </Section>
);

export default HomeInterests;
