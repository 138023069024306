import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './SectionFigure.module.scss';

const SectionFigure = ({ src, alt, className }) => {
    const imageClass = classNames(styles.image, className);

    return (
        <figure className={styles.root}>
            <img src={src} className={imageClass} alt={alt} />
        </figure>
    );
};

SectionFigure.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
};

SectionFigure.defaultProps = {
    className: null,
};

export default SectionFigure;
