import React, { useEffect, useState } from 'react';

import { isMicrosoft } from '../../utility';

import Container from '../Container';

import styles from './HomeHero.module.scss';

const HomeHero = () => {
    const INITIAL_OFFSET = 0;
    const SCALE_FACTOR = -2;

    const [offset, setOffset] = useState(INITIAL_OFFSET);

    useEffect(() => {
        if (isMicrosoft()) {
            return () => null;
        }

        let animationFrame = null;

        const loop = () => {
            const wOffset = window.pageYOffset;
            const wHeight = window.innerHeight;

            if (offset > wHeight) {
                return;
            }

            setOffset(wOffset / SCALE_FACTOR);
            animationFrame = requestAnimationFrame(loop);
        };

        loop();

        return () => {
            cancelAnimationFrame(animationFrame);
        };
    }, []);

    return (
        <section className={styles.root}>
            <Container className={styles.container}>
                <h2
                    className={styles.title}
                    style={{ transform: `translate3d(0, ${offset}px, 0)` }}
                >
                    Physiotherapy that gets you Strong, Inside & Out
                </h2>
                <i className={styles.bg} />
            </Container>
        </section>
    );
};

export default HomeHero;
