import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import styles from './Button.module.scss';

const Button = ({ to, children, isDisabled, onClick }) => {
    const rootClass = classNames(styles.root, isDisabled ? styles.rootDisabled : null);

    if (to) {
        return (
            <Link to={to} className={rootClass} onClick={(event) => onClick(event)}>
                {children}
            </Link>
        );
    }

    return (
        <button
            type="button"
            onClick={(event) => onClick(event)}
            className={rootClass}
            disabled={isDisabled}
        >
            {children}
        </button>
    );
};

Button.propTypes = {
    to: PropTypes.string,
    children: PropTypes.node,
    isDisabled: PropTypes.bool,
    onClick: PropTypes.func,
};

Button.defaultProps = {
    to: null,
    children: null,
    isDisabled: false,
    onClick: () => null,
};

export default Button;
