import React from 'react';

import NetworkListItem from './NetworkListItem';

import essexImage from '../../images/network/essex.jpg';
import poweringImage from '../../images/network/powering.jpg';
import kannImage from '../../images/network/kann.jpg';
import personalImage from '../../images/network/personal.jpg';
import chelmsfordImage from '../../images/network/chelmsford.jpg';
import myoImage from '../../images/network/myo.jpg';

import styles from './NetworkList.module.scss';

const data = [
    {
        id: 'powering',
        title: 'Will Wayland',
        subtitle: 'Strength & Conditioning',
        description: 'Powering Through Performance',
        image: poweringImage,
    },
    {
        id: 'kann',
        title: 'Ewan Kannegieter',
        subtitle: 'Podiatric Surgery',
        description: 'Kann Foot Surgery',
        image: kannImage,
    },
    {
        id: 'personal',
        title: 'Powering Through Performance',
        subtitle: 'Personal Training and Group Exercise',
        image: personalImage,
    },
    {
        id: 'chelmsford',
        title: 'James Noake',
        subtitle: 'Consultant Sports Physician',
        description: 'The Chelmsford & Pure Sports Medicine',
        image: chelmsfordImage,
    },
];

const NetworkList = () => (
    <ul className={styles.root}>
        {data.map((item) => (
            <li key={item.id} className={styles.item}>
                <NetworkListItem {...item} />
            </li>
        ))}
    </ul>
);

export default NetworkList;
