import React from 'react';

import Section from '../../Section';
import SectionTitle from '../../SectionTitle';
import SectionFigure from '../../SectionFigure';

import profile from '../../../images/home/profile.png';

import styles from './HomeAbout.module.scss';

const HomeAbout = () => (
    <Section
        title={
            <SectionTitle color="white" number="01">
                About Me
            </SectionTitle>
        }
        figure={<SectionFigure className={styles.image} src={profile} alt="Alex Kraszweski" />}
        background="primary"
        color="white"
        isBaseless
        isReverse
        hasLogo
        hasDescription
    >
        <p>
            I qualified as a Physiotherapist in 2012 after completing my Master&#39;s Degree in
            Physiotherapy at the University of Anglia, following an undergraduate degree in Sport &
            Exercise Science at the University of Portsmouth in 2009. I currently work in the NHS as
            a Team Lead Physiotherapist at Braintree Community Hospital, as well as offering private
            Physiotherapy services in Chelmsford, and elsewhere by request.
        </p>
        <p>
            Rather than bore you with all the other qualifications and letters that come with a lot
            of studying and training, let&#39;s keep it simple. I&#39;m a Physiotherapist, and I
            love to help people get out of pain, get back to doing what they love, and getting them
            as fit and strong as they desire.
        </p>
    </Section>
);

export default HomeAbout;
