import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './InterestListItem.module.scss';

const InterestListItem = ({ title, description, image, alt, isReverse }) => (
    <li className={styles.root}>
        <span className={classNames(styles.content, isReverse ? styles.contentReverse : null)}>
            <span className={styles.title}>{title}</span>
            {Boolean(description) && <small className={styles.description}>{description}</small>}
        </span>
        <img className={styles.image} src={image} alt={alt} />
    </li>
);

InterestListItem.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    isReverse: PropTypes.bool,
};

InterestListItem.defaultProps = {
    description: null,
    isReverse: false,
};

export default InterestListItem;
