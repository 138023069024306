import React from 'react';

import Section from '../../Section';
import SectionTitle from '../../SectionTitle';

import bg from '../../../images/home/approach.jpg';

const HomeApproach = () => (
    <Section
        title={
            <SectionTitle color="white" number="02">
                My Approach
            </SectionTitle>
        }
        background={['xdark', bg]}
        color="white"
        hasDescription
    >
        <p>
            Physiotherapy comes in many different forms, and it&#39;s important you see someone who
            is the right fit to work with you. I am more interested in rehabilitation than
            treatment, and helping you take an active role in getting yourself out of pain and back
            to doing what you love. Like most therapists, I can use &#39;hands on&#39; treatments,
            but this is never the focus of my work. The focus is on understanding your problem in as
            much detail as possible, and developing a solution to help us both solve this.{' '}
        </p>
        <p>
            My approach focuses on a thorough and extensive assessment to fully understand your
            problem and how it is stopping you living your life on your terms. This may well take
            longer than any assessment you&#39;ve had in the past, but taking the time early on
            allows a treatment and rehabilitation plan to be laser focused to get you back to where
            you want to be. My assessment process has been developed from spending time with some of
            the leading professionals in the health and fitness industries across the world.
        </p>
        <p>
            My goal for you is sustainable, long term results and not quick fixes. If you are
            looking to take control of your pain and get back to what you love, get in touch to find
            out how I can help you.{' '}
        </p>
    </Section>
);

export default HomeApproach;
